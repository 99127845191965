<template>
  <div>
    <!--    <div style="margin:10px 0">-->
    <!--      <a-input placeholder="回车搜索" @keydown.enter="search" v-model="sValue"/>-->
    <!--    </div>-->
    <div style="margin: 15px 0">
      <a-input-search v-model="sValue" placeholder="回车搜索" @search="search" @keydown.enter="search"
                      style="width: 20%"/>
      <div style="float: right">
        <a-button type="primary" @click="isAdd=true">新增黑名单</a-button>
      </div>
    </div>
    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          :pagination="false"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">

          <a @click="open_editor(record.id)">编辑</a>

        </template>

      </a-table>
    </div>

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="!isSearch"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="isSearch"
                  :default-current="1"
                  v-model="currSearch"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />


    <a-modal title="黑名单修改" :width="600" v-model="black_editor_status.show" @ok="black_editor_ok"
             @cancel="black_editor_cancel"
             okText="确定修改" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="Steam账号:" v-model="black_editor_status.black_tempObj.steam_account"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="Steam17位Id:" v-model="black_editor_status.black_tempObj.steamid"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="手机:" v-model="black_editor_status.black_tempObj.phone"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="缘由:" v-model="black_editor_status.black_tempObj.reason"/>
      </div>

      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          等级:
        </div>

        <a-select style="width: 90.1%" v-model="black_editor_status.black_tempObj.level">
          <a-select-option :value="1">一级</a-select-option>
          <a-select-option :value="2">二级</a-select-option>
          <a-select-option :value="3">三级</a-select-option>
        </a-select>

      </div>

      <div style="margin-bottom: 16px;width: 100%">
        <a-button type="danger" style="width: 100%" @click="delIt">删除此条</a-button>
      </div>


    </a-modal>


    <a-modal title="添加黑名单" :width="600" v-model="isAdd" @ok="add_black_list"
             @cancel="()=>{this.isAdd=false,this.Abj={}}"
             okText="确定修改" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="Steam账号:" v-model="Abj.steam_account"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="Steam17位Id:" v-model="Abj.steamid"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="手机:" v-model="Abj.phone"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="缘由:" v-model="Abj.reason"/>
      </div>

      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          等级:
        </div>

        <a-select style="width: 90.1%" v-model="Abj.level">
          <a-select-option :value="1">一级</a-select-option>
          <a-select-option :value="2">二级</a-select-option>
          <a-select-option :value="3">三级</a-select-option>
        </a-select>

      </div>


    </a-modal>

  </div>
</template>

<script>
const key = '123'
export default {
  name: "BlackList",
  data() {
    return {
      curr: 1,
      currSearch: 1,

      black_editor_status: {
        show: false,
        black_tempObj: {}
      },

      isAdd: false,
      Abj: {},

      copyData: {},

      isSearch: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",

        },
        {
          title: 'Steam账号',
          dataIndex: 'steam_account',
          key: 'steam_account',
          align: "center",
        },

        {
          title: 'Steam17位id',
          dataIndex: 'steamid',
          key: 'steamid',
          align: "center",

        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key: 'phone',
          align: "center",

        },
        {
          title: '危险等级',
          dataIndex: 'level',
          key: 'level',
          align: "center",

        },
        {
          title: '入黑缘由',
          dataIndex: 'reason',
          key: 'reason',
          align: "center",
        },

        {
          title: '入黑时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          align: "center",
        },
        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: "center",
        },
      ],
      data: [],
      returnBack: [],
      sValue: ''
    }
  },
  methods: {
    add_black_list() {
      this.$axios.post('/Admin/Api/BlackList/AddBlackList', {
        ...this.Abj
      }).then(v => {
        this.$message.success({content: v.data.msg, key, duration: 1})
        setTimeout(() => {
          this.$router.go(0)
        }, 700)
      }).catch(err => this.$message.error({content: err.data, key, duration: 1})
      )
    },
    search() {
      this.isSearch = true
      this.currSearch = 1
      this.$axios.post('/Admin/Api/BlackList/SearchBlackList', {
        "value": this.sValue,
        "page": 1,
        "pageCount": 12
      }).then(v => {
        this.returnBack = JSON.parse(v.data.data)
        this.data = this.returnBack.list
        this.$message.success({content: v.data.msg, key, duration: 1})
      }).catch(err => this.$message.error({content: err.msg, key, duration: 1})
      )
    },
    open_editor(id) {
      this.black_editor_status.black_tempObj = this.data.find(v => v.id == id)
      Object.assign(this.copyData, this.black_editor_status.black_tempObj)
      this.black_editor_status.show = true
    },
    black_editor_ok() {
      this.$axios.post('/Admin/Api/BlackList/ChangeBlackList', {
        ...this.black_editor_status.black_tempObj
      }).then(value => {
        this.$message.success({content: value.data.msg, key, duration: 1})
      }).catch(err => {
            this.$message.error(err.msg)
            this.black_editor_cancel()
          }
      )
      this.black_editor_status.show = false
    },
    black_editor_cancel() {
      this.$message.error({content: '取消修改', key, duration: 1})
      Object.assign(this.black_editor_status.black_tempObj, this.copyData)

    },
    delIt() {
      this.$axios.post('/Admin/Api/BlackList/DelBlackList', {
        id: this.black_editor_status.black_tempObj.id
      }).then(value => {
        this.$message.success({content: value.data.msg, key, duration: 1})
        this.data = this.data.filter(v => v.id != this.black_editor_status.black_tempObj.id)
      }).catch(err => {
        this.$message.success({content: err.msg, key, duration: 1})
      })
      this.black_editor_status.show = false
    }


  },
  watch: {
    curr: {
      async handler(n) {
        this.data = []
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/BlackList/GetBlackList', {
          "page": n,
          "pageCount": 12
        })).data.data)
        this.data = this.returnBack.list
      }
    },
    currSearch: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/BlackList/SearchBlackList', {
          "value": this.sValue,
          "page": n,
          "pageCount": 12
        })).data.data)
        this.isSearch = true
        this.data = this.returnBack.list
      }
    },
  },

  async created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/BlackList/GetBlackList', {
      "page": 1,
      "pageCount": 12
    })).data.data)
    this.data = this.returnBack.list

  }
}
</script>

<style scoped>

</style>